import { html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { CompanyListing } from "@pentacode/core/src/model";
import { app } from "../init";
import { Dialog } from "./dialog";
import { alert } from "./alert-dialog";
import "./scroller";

@customElement("ptc-select-company-dialog")
export class SelectCompanyDialog extends Dialog<void, number> {
    readonly preventDismiss = true;

    @state()
    private _companies: CompanyListing[] = [];

    @state()
    private _filter = "";

    private async _load() {
        this.loading = true;

        try {
            this._companies = await app.api.listCompanies();
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this.loading = false;
    }

    private async _selectCompany(companyId: number) {
        this.done(companyId);
    }

    async show() {
        void this._load();
        return super.show();
    }

    static styles = [
        ...Dialog.styles,
        css`
            .inner {
                display: flex;
                flex-direction: column;
                text-align: center;
            }

            .inner > * {
                margin: 0 0.5em 0.5em 0.5em;
            }

            .title {
                margin: 1em;
                font-size: var(--font-size-large);
            }

            ptc-scroller {
                flex: 1;
            }
        `,
    ];

    renderContent() {
        if (!app.account) {
            return html``;
        }

        const fs = this._filter.toLowerCase();

        return html`
            <div class="title">Bitte wählen Sie ein Unternehmen:</div>

            <input
                type="search"
                placeholder="Filtern..."
                @input=${(e: Event) => (this._filter = (e.target as HTMLInputElement).value)}
                ?hidden=${this._companies.length < 5}
            />

            ${this._companies
                .filter(
                    (c) =>
                        !fs ||
                        c.name.toLowerCase().includes(fs) ||
                        c.venues.some((v) => v.name.toLowerCase().includes(fs)) ||
                        c.id.toString() === fs
                )
                .map(
                    (company) => html`
                        <button class="company ghost" @click=${() => this._selectCompany(company.id)}>
                            ${company.name}
                        </button>
                    `
                )}

            <button class="transparent" ?hidden=${!app.company} @click=${() => this.done()}>Abbrechen</button>
        `;
    }
}
