import "./scroller";
import "./popover";
import "./drawer";
import { LitElement, html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { Employee, RosterNote } from "@pentacode/core/src/model";
import { app } from "../init";
import { dateAdd, formatDateShort } from "@pentacode/core/src/util";
import { shared } from "../styles";
import { matchesFilters } from "@pentacode/core/src/filters";

@customElement("ptc-roster-note")
export class RosterNoteEl extends LitElement {
    @property({ attribute: false })
    rosterNote: RosterNote;

    @property({ attribute: false })
    employee: Employee;

    @property({ type: Boolean })
    collapsed = true;

    @property({ type: Number })
    maxHeight = 50;

    @query("pre")
    private _noteTextContainer: HTMLPreElement;

    @query(".box")
    private _box: HTMLDivElement;

    @query(".expand-button")
    private _expandButton: HTMLDivElement;

    private get _expandable() {
        return this._noteTextContainer && this._noteTextContainer.offsetHeight > this.maxHeight;
    }

    private _mutationObserver = new MutationObserver(() => this._updateDynamicStyles());
    private _intersectionObserver = new IntersectionObserver(() => this._updateDynamicStyles());

    private async _updateDynamicStyles() {
        if (!this._expandButton) {
            await this.updateComplete;
        }
        this._expandButton.style.display = this._expandable ? "" : "none";
        this._box.style.paddingBottom = this._expandable && !this.collapsed ? "2em" : "";
    }

    connectedCallback() {
        super.connectedCallback();
        this._mutationObserver.observe(this, { childList: true, subtree: true });
        this._intersectionObserver.observe(this);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this._mutationObserver.disconnect();
        this._intersectionObserver.disconnect();
    }

    updated() {
        void this._updateDynamicStyles();
    }

    static styles = [
        shared,
        css`
            :host {
                display: block;
            }
        `,
    ];

    render() {
        const company = app.company;
        if (!company) {
            return;
        }
        const note = this.rosterNote;
        const departments = app.departments.filter((department) =>
            matchesFilters(note.filters, { company, department })
        );
        const ownDepartments = departments.filter((d) => this.employee.positions.some((p) => p.departmentId === d.id));
        return html`
            <div
                class="padded box relative"
                style="--color-highlight: ${note.color || "unset"}; transition: padding-bottom 0.2s;"
            >
                <div class="subtle spacing horizontal layout" style="margin-bottom: 0.25em">
                    <div class="stretch">
                        ${formatDateShort(note.start)}
                        ${note.end !== dateAdd(note.start, { days: 1 }) ? `- ${formatDateShort(note.end)}` : ""}
                    </div>
                    <div class="smaller pills" style="margin-right: -0.5em; margin-top: -0.25em;">
                        ${ownDepartments.map((d) => html`<div class="pill">${d.name}</div>`)}
                        ${departments.length > ownDepartments.length
                            ? html`<div class="pill">+${departments.length - ownDepartments.length}</div>`
                            : ""}
                    </div>
                </div>

                <ptc-drawer .collapsedHeight=${this.maxHeight} .collapsed=${this.collapsed}>
                    <pre style="white-space: pre-wrap; line-height: 1.3em;">${note.text}</pre>
                </ptc-drawer>

                <div class="absolute bottom right expand-button" style="background: rgba(255, 255, 255, 0.9);">
                    ${this.collapsed
                        ? html`<div class="padded link" @click=${() => (this.collapsed = false)}>
                              mehr <i class="smaller arrow-right"></i>
                          </div>`
                        : html` <div class="padded link" @click=${() => (this.collapsed = true)}>
                              <i class="smaller arrow-left"></i> weniger
                          </div>`}
                </div>
            </div>
        `;
    }
}
