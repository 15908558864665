import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { config, shared, mixins } from "../../styles";
import { StateMixin } from "../../mixins/state";
import { ServiceWorker } from "../../mixins/service-worker";
import { Routing, ready, RouteArgs } from "../../mixins/routing";
import { ErrorHandling } from "../../mixins/error-handling";
import { app } from "../../init";
import "../avatar";
import "../popover";
import { SelectCompanyDialog } from "../select-company-dialog";
import { singleton } from "../../lib/singleton";
import "../set-password";
import "./login";
import "./settings";
import "./home";
import "../join-company";
import { Permission } from "@pentacode/core/src/permissions";

@customElement("ptc-staff-app")
export class StaffApp extends Routing(ServiceWorker(ErrorHandling(StateMixin(LitElement)))) {
    readonly routePattern = /^([^/]*)/;

    get helpPage(): string | undefined {
        return "handbuch/mitarbeiter-app";
    }

    @property({ type: Boolean, reflect: true, attribute: "singleton-container" })
    readonly singletonContainer = true;

    @property({ reflect: true })
    page: string = "";

    @singleton("ptc-select-company-dialog")
    private _selectCompanyDialog: SelectCompanyDialog;

    private _pages = [
        "login",
        "home",
        "time",
        "tracking",
        "roster",
        "absences",
        "availabilities",
        "documents",
        "settings",
        "setpwd",
        "join",
    ];

    constructor() {
        super();
        void app.loaded.then(() => {
            ready();
        });
    }

    async handleRoute([page]: RouteArgs, _params: { [prop: string]: any }, path: string) {
        page = page as string;
        if (!app.account && !["login", "setpwd", "join"].includes(page)) {
            this.go("login", { next: path }, true);
            return;
        }

        if (
            !this._pages.includes(page) ||
            (["time", "tracking", "roster", "absences", "ledgers"].includes(page) &&
                !app.hasPermission(`staff.${page}` as Permission))
        ) {
            this.redirect("home");
            return;
        }

        const loadingScreen = document.querySelector(".loading-screen") as HTMLElement;
        loadingScreen && (loadingScreen.style.display = "none");

        if (app.account && !app.company) {
            const companyId = (await this._selectCompanyDialog.show())!;
            await app.selectCompany(companyId);
            window.location.reload();
            return;
        }

        this.page = page;
    }

    static styles = [
        config,
        shared,
        css`
            :host {
                --font-family: "Nunito";
                display: block;
                ${mixins.fullbleed()};
                display: flex;
                flex-direction: column;
                padding-top: env(safe-area-inset-top);
            }

            .main {
                flex: 1;
                align-self: center;
                width: 100%;
                box-sizing: border-box;
                max-width: 500px;
                display: flex;
                flex-direction: row;
                overflow: hidden;
                background: var(--color-bg);
                position: relative;
            }

            ptc-staff-home,
            ptc-staff-settings {
                transition:
                    transform 0.3s cubic-bezier(0.35, 0.35, 0.1, 1.1),
                    opacity 0.3s;
            }

            :host([page="settings"]) ptc-staff-home {
                transform: translate(-100%, 0);
                opacity: 0;
            }

            :host(:not([page="settings"])) ptc-staff-settings {
                transform: translate(100%, 0);
                opacity: 0;
            }

            @media (min-width: 500px) {
                :host {
                    padding: 1em;
                }

                .main {
                    border-radius: 1em;
                    margin: auto;
                    box-shadow: rgb(0 0 0 / 50%) 0px 0px 1em -0.5em;
                    max-height: 900px;
                }
            }
        `,
    ];

    render() {
        return html`
            <div class="main">
                <ptc-staff-home class="fullbleed"></ptc-staff-home>
                <ptc-staff-settings class="fullbleed"></ptc-staff-settings>
            </div>

            <ptc-staff-login class="fullbleed" ?hidden=${this.page !== "login"}></ptc-staff-login>
            <ptc-set-password class="fullbleed" ?hidden=${this.page !== "setpwd"}></ptc-set-password>
            <ptc-join-company class="fullbleed" ?hidden=${this.page !== "join"} .scope=${"staff"}></ptc-join-company>
        `;
    }
}
