import {
    Document,
    DocumentTag,
    fileIcon,
    FormStatus,
    formStatusColor,
    formStatusLabel,
} from "@pentacode/core/src/model";
import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { StateMixin } from "../../mixins/state";
import { singleton } from "../../lib/singleton";
import { app } from "../../init";
import { shared } from "../../styles";
import { alert } from "../alert-dialog";
import "../scroller";
import { DocumentDialog } from "../document-dialog";
import { formatDate, formatMonth, getRange } from "@pentacode/core/src/util";
import "./../spinner";
import { routeProperty, Routing } from "../../mixins/routing";

@customElement("ptc-staff-documents")
export class Documents extends Routing(StateMixin(LitElement)) {
    routePattern = /^(home|documents)/;

    @property({ reflect: true, type: Boolean })
    expanded = false;

    @routeProperty({ param: "openDoc", type: Number })
    private _openDoc: number;

    @state()
    private _loading = false;

    @state()
    private _documents: Document[] = [];

    @state()
    private _months: {
        date: string;
        documents: Document[];
    }[];

    @state()
    private _selectedTag: DocumentTag | null = null;

    @singleton("ptc-document-dialog")
    private _documentDialog: DocumentDialog;

    refresh() {
        return this._load();
    }

    async handleRoute() {
        await this._load();

        if (this._openDoc) {
            const document = this._documents.find((d) => d.id === this._openDoc);
            if (document) {
                await this._openDocument(document);
            }
            this.go(null, { openDoc: undefined });
        }
    }

    async connectedCallback() {
        super.connectedCallback();
        await app.loaded;
        if (app.account) {
            void this._load();
        }
    }

    private async _load() {
        if (!app.profile) {
            return;
        }

        this._loading = true;

        try {
            const documents = await app.api.getDocuments({ employeeId: app.profile.id });

            this._documents = documents
                .filter((d) => d.url || d.form?.status === FormStatus.InProgress)
                .sort((a, b) => (a.date < b.date ? -1 : 1));

            const months: { date: string; documents: Document[] }[] = [];

            for (const document of this._documents) {
                const date = getRange(document.date, "month").from;
                if (date === months[0]?.date) {
                    months[0].documents.push(document);
                } else {
                    months.unshift({ date, documents: [document] });
                }
            }

            this._months = months;
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _openDocument(document: Document) {
        const edited = await this._documentDialog.show(document);
        if (edited) {
            // Reload documents in the background
            void this._load();
        }
    }

    static styles = [
        shared,
        css`
            :host {
                position: relative;
            }

            .documents-grid {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(15em, 1fr));
                grid-gap: 0.5em;
            }
        `,
    ];

    render() {
        return this.expanded ? this._renderVertical() : this._renderHorizontal();
    }

    private _renderHorizontal() {
        return html`
            ${!this._documents.length
                ? html`
                      <div class="stretch centering vertical layout">
                          <i class="giant light faded files"></i>
                          <div>Es liegen noch keine Dokumente für Sie vor.</div>
                      </div>
                  `
                : html`
                      <div class="horizontal scroller" style="margin-top: -1em">
                          <div class="margined horizontal layout">
                              ${this._months.map(
                                  ({ date, documents }) => html`
                                      <div>
                                          <div
                                              class="small padded sticky-left semibold blue colored-text"
                                              style="display: inline-block"
                                          >
                                              ${formatMonth(date)}
                                          </div>
                                          <div class="horizontal spacing layout">
                                              ${documents.map(
                                                  (document) => html`
                                                      <div
                                                          class="rounded padded click horizontal center-aligning layout ${document
                                                              .form?.status === FormStatus.InProgress
                                                              ? "shining"
                                                              : ""}"
                                                          style="padding-left: 0;"
                                                          @click=${() => this._openDocument(document)}
                                                      >
                                                          <i class="massive light ${fileIcon(document.type)}"></i>
                                                          <div class="stretch">
                                                              <div class="small semibold ellipsis bottom-margined">
                                                                  ${document.name}
                                                              </div>
                                                              <div class="tiny horizontal spacing layout">
                                                                  <div class="slim pill">
                                                                      <i class="calendar"></i>${formatDate(
                                                                          document.date,
                                                                          false
                                                                      )}
                                                                  </div>
                                                                  ${document.tags.length > 1
                                                                      ? html`
                                                                            <div
                                                                                class="slim pill ellipsis"
                                                                                style="max-width: 100%"
                                                                            >
                                                                                <i class="tags"></i>
                                                                                ${document.tags.length} Tags
                                                                            </div>
                                                                        `
                                                                      : document.tags.map(
                                                                            (tag) => html`
                                                                                <div
                                                                                    class="slim pill ellipsis"
                                                                                    style="max-width: 100%; --color-highlight: ${tag.color}"
                                                                                >
                                                                                    <i class="tag"></i>${tag.name}
                                                                                </div>
                                                                            `
                                                                        )}
                                                                  ${document.form
                                                                      ? html`<div
                                                                            class="pill"
                                                                            style="--color-highlight: ${formStatusColor(
                                                                                document.form.status
                                                                            )}"
                                                                        >
                                                                            <i class="pen-field"></i> ${formStatusLabel(
                                                                                document.form.status
                                                                            )}
                                                                        </div>`
                                                                      : ""}
                                                              </div>
                                                          </div>
                                                      </div>
                                                  `
                                              )}
                                          </div>
                                      </div>
                                  `
                              )}
                          </div>
                      </div>
                  `}
        `;
    }

    private _renderVertical() {
        return html`
            <div class="fullbleed vertical layout">
                <div class="horizontally-padded bottom-padded horizontal layout border-bottom">
                    <div class="small spacing wrapping center-aligning horizontal layout stretch">
                        <button
                            class="slim ${!this._selectedTag ? "primary" : "transparent"}"
                            @click=${() => (this._selectedTag = null)}
                        >
                            <i class="tags"></i> Alle
                            <span class="subtle" style="display: inline-block; margin-left: 0.25em;">
                                ${this._documents.length}
                            </span>
                        </button>
                        ${app.company?.documentTags?.map((tag) => {
                            const count = this._documents.filter((doc) => doc.tags.some((t) => t.id === tag.id)).length;
                            return count
                                ? html`
                                      <button
                                          class="slim ${this._selectedTag?.id === tag.id ? "primary" : "transparent"}"
                                          style="--color-highlight: ${tag.color}"
                                          @click=${() => (this._selectedTag = tag)}
                                      >
                                          <i class="tag"></i> ${tag.name}
                                          <span class="subtle" style="display: inline-block; margin-left: 0.25em;">
                                              ${count}
                                          </span>
                                      </button>
                                  `
                                : "";
                        })}
                    </div>
                </div>

                ${!this._documents.length
                    ? html`
                          <div class="stretch centering vertical layout">
                              <i class="giant light faded files"></i>
                              <div>Es liegen noch keine Dokumente für Sie vor.</div>
                          </div>
                      `
                    : html`
                          <ptc-scroller class="stretch">
                              ${this._months.map(({ date, documents }) => {
                                  documents = documents.filter(
                                      (doc) =>
                                          !this._selectedTag || doc.tags.some((tag) => tag.id === this._selectedTag?.id)
                                  );
                                  return !documents.length
                                      ? null
                                      : html`
                                            <div
                                                class="small padded sticky border-bottom subtle semibold"
                                                style="background: var(--color-background)"
                                            >
                                                ${formatMonth(date)}
                                            </div>
                                            ${documents
                                                .filter(
                                                    (doc) =>
                                                        !this._selectedTag ||
                                                        doc.tags.some((tag) => tag.id === this._selectedTag?.id)
                                                )
                                                .map(
                                                    (document) => html`
                                                        <div
                                                            class="padded click horizontal center-aligning layout border-bottom"
                                                            style="padding-left: 0;"
                                                            @click=${() => this._openDocument(document)}
                                                        >
                                                            <i class="massive light ${fileIcon(document.type)}"></i>
                                                            <div class="stretch collapse">
                                                                <div class="small semibold ellipsis bottom-margined">
                                                                    ${document.name}
                                                                </div>
                                                                <div class="tiny horizontal spacing layout">
                                                                    <div class="slim pill">
                                                                        <i class="calendar"></i> ${formatDate(
                                                                            document.date,
                                                                            false
                                                                        )}
                                                                    </div>
                                                                    ${document.tags.map(
                                                                        (tag) => html`
                                                                            <div
                                                                                class="slim pill ellipsis"
                                                                                style="max-width: 100%; --color-highlight: ${tag.color}"
                                                                            >
                                                                                <i class="tag"></i> ${tag.name}
                                                                            </div>
                                                                        `
                                                                    )}
                                                                    ${document.form
                                                                        ? html`<div
                                                                              class="pill"
                                                                              style="--color-highlight: ${formStatusColor(
                                                                                  document.form.status
                                                                              )}"
                                                                          >
                                                                              <i class="pen-field"></i>
                                                                              ${formStatusLabel(document.form.status)}
                                                                          </div>`
                                                                        : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    `
                                                )}
                                        `;
                              })}
                          </ptc-scroller>
                      `}
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
